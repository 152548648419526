<script>
import icon_1 from "../../assets/img/menu/icon_1.png"
import icon_2 from "../../assets/img/menu/icon_2.png"
import icon_3 from "../../assets/img/menu/icon_3.png"
import icon_4 from "../../assets/img/menu/icon_4.png"

export default {
  name: "index",
  data(){
    return{
      navData: [],
      dataAnalysis: icon_1,
      pesticide: icon_2,
      history: icon_3,
      system: icon_4,
      activateIndex: 0
    }
  },
  watch:{
    '$route'(to,from) {
      // console.log('路由发生变化了');
      console.log('从:', from.path);
      console.log('到:', to.path);
      if(from.path === '/dataAnalysis'){
        this.activateIndex = this.navData.findIndex(item => item.path === to.path)
      }
    },
  },
  created() {
    this.get_nav();
  },
  methods:{
    // 获取导航栏
    get_nav() {
      this.navData = this.$router.options.routes[0].children;
      this.activateIndex = this.navData.findIndex(item => item.path === this.$route.path)
    },
    clickMenu(item,index){
      if(this.$route.path === item.path){
        return false
      }
      this.$router.push(item.path);
      this.activateIndex = index
    }
  }
}
</script>

<template>
  <div class="menu-page">
    <div class="menu-item" v-for="(item,index) in navData" :key="index" @click="clickMenu(item,index)" :class="activateIndex === index ? 'menu-item-active' : ''">
      <div class="menu-icon">
        <img :src="item.icon" style="width: 100%;height: 100%;">
      </div>
      <div class="menu-name">
        <span>{{ item.name }}</span>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.menu-page{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: #F6F7FB;
  display: flex;
  flex-direction: column;
  cursor:pointer;

  .menu-item{
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .menu-icon{
      width: 54px;
      height: 54px;
      display: flex;
    }
    .menu-name{
      font-weight: 400;
      font-size: 28px;
      color: #666666;
    }
  }

  .menu-item-active{
    width: 100%;
    flex: 1;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .menu-icon{
      width: 54px;
      height: 54px;
      display: flex;
    }
    .menu-name{
      font-weight: bold;
      font-size: 28px;
      color: #333333;
    }
  }
}
</style>
