import Vue from 'vue'
import App from './App.vue'
import router from './router'

// vuex
import store from './store/index';

// elementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

// 字典标签组件
import DictTag from '@/components/DictTag'
// 全局组件挂载
Vue.component('DictTag', DictTag)

import animated from 'animate.css'
Vue.use(animated)

// 模态框对象
import modal from './plugins/modal'
Vue.prototype.$modal = modal

// api请求
import {getApiConfigParams, getApiConfig, postApiConfig, deleteApiConfig, deleteApiConfigParams, putApiConfig} from "@/api/apiConfig"
Vue.prototype.getApiConfigParams = getApiConfigParams;
Vue.prototype.getApiConfig = getApiConfig;
Vue.prototype.postApiConfig = postApiConfig;
Vue.prototype.deleteApiConfig = deleteApiConfig;
Vue.prototype.deleteApiConfigParams = deleteApiConfigParams;
Vue.prototype.putApiConfig = putApiConfig;

import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

Vue.config.productionTip = false;

// 跳转后返回顶部
router.afterEach(() => {
    window.scrollTo(0,0);
})

new Vue({
  router,
    store,
  render: h => h(App)
}).$mount('#app')
