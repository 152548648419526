<template>
  <div class="home-page">
    <el-container class="home-container">
      <!-- 侧边 -->
      <el-aside width="200px" class="home-container-aside">
        <!-- logo -->
        <logoBox class="logo-box"/>
        <!-- 菜单 -->
        <menuBox class="menu-box"/>
      </el-aside>
      <el-container>
        <!-- 标题 -->
        <el-header height="50px" class="home-container-header">
          <!-- 用户角色 -->
          <userRole/>
        </el-header>
        <!-- 主体 -->
        <el-main class="home-container-main">
          <div class="home-container-main-content" :style="{backgroundColor: path === '/dataAnalysis' ? '#f9f9f9' : '#ffffff'}">
            <router-view/>
          </div>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import logoBox from "../components/logo/index.vue";
import userRole from "../components/userRole/index.vue";
import menuBox from "../components/menu/index.vue";
export default {
  name: "index",
  components:{
    logoBox,
    userRole,
    menuBox,
  },
  watch: {
    // 监听$route的变化
    '$route'(to) {
      this.path = to.path;
      console.log("当前路由路径:", to.path);
    },
  },
  data(){
    return{
      path:'',
    }
  },
  mounted() {
    this.getCurrentRouteInfo()
  },
  methods:{
    getCurrentRouteInfo() {
      this.path = this.$route.path
      console.log("当前路由路径:", this.$route.path);
      console.log("路由名称:", this.$route.name);
      console.log("动态参数:", this.$route.params);
      console.log("查询参数:", this.$route.query);
    }
  }
}
</script>

<style lang="scss">
.home-page{
  width: 100%;
  height: 100vh;
  box-sizing: border-box;

  .home-container{
    width: 100%;
    height: 100vh;
    border: 1px solid #dddede;

    .home-container-aside{
      width: 100%;
      border-right: 1px solid #dddede;
      display: flex;
      flex-direction: column;

      .logo-box{
        height: 50px;
        border-bottom: 1px solid #dddede;
      }
      .menu-box{
        flex: 1;
      }
    }
    .home-container-header{
      width: 100%;
      border-bottom: 1px solid #dddede;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .home-container-main{
      width: 100%;
      height: 100%;
      background-color: #f9f9f9;

      .home-container-main-content{
        width: 100%;
        min-height: 100%;
        //background-color: #ffffff;
        border-radius: 20px;
      }
    }
  }
}
</style>
