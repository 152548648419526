import axios from "axios";
import {
    getToken
} from '@/utils/cookies'
import {
    Message, MessageBox
} from 'element-ui'
import Cookies from "js-cookie";

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'

// 创建一个axios 实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API,
    timeout: 1000 * 20
})

// 请求拦截器
service.interceptors.request.use(config => {
    // 设置token请求头
    if (getToken()) {
        config.headers['Authorization'] = 'Bearer ' + getToken()
    }
    return config;
}, error => {
    console.error(error);
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 响应状态码
    const code = res.data.code;
    if (code === 200) {
        return res.data
    } else if (code === 401) {
        // 清除token
        Cookies.remove("token");
        // 跳转到登录页面
        MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', { confirmButtonText: '重新登录', cancelButtonText: '取消', type: 'warning' }).then(() => {
            location.href = '/login';
        })
    } else if(code === 500){
        // Message.error({ message: res.data.msg })
        return res.data
    } else {
        Message.error({ message: res.data.msg })
    }
}, error => {
    console.error('err' + error);
    let { message } = error;
    if (message == "Network Error") {
        message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
        message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
        message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Message.error({
        message: message
    })
})

export default service
