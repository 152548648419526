import request from '@/utils/request'

// get-请求
export function getApiConfigParams(url,data) {
    return request({
        url: url,
        method: 'get',
        params: data
    })
}

export function getApiConfig(url,data) {
    return request({
        url: url + data,
        method: 'get'
    })
}

// post-请求
export function postApiConfig(url,data){
    return request({
        url: url,
        data: data,
        method: 'post',
    })
}

// delete-请求
export function deleteApiConfig(url,data){
    return request({
        url: url + data,
        method: 'delete'
    })
}

export function deleteApiConfigParams(url,data){
    return request({
        url: url,
        method: 'delete',
        params: data
    })
}

// put-请求
export function putApiConfig(url,data){
    return request({
        url: url,
        data: data,
        method: 'put',
    })
}
