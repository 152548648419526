import Vue from 'vue'
import VueRouter from 'vue-router'

import { getToken } from '@/utils/cookies'

import home from "@/views/index"

Vue.use(VueRouter);

import dataAnalysis from "../assets/img/menu/icon_1.png"
import pesticide from "../assets/img/menu/icon_2.png"
import history from "../assets/img/menu/icon_3.png"
import system from "../assets/img/menu/icon_4.png"
import beast from "../assets/img/menu/icon_5.png"

const routes = [
  {
    path: "/",
    component: home,
    redirect: '/dataAnalysis',
    children: [
      {
        path: '/dataAnalysis',
        name: '数据总览',
        icon: dataAnalysis,
        component: () => import('@/views/dataAnalysis/index.vue')
      },{
        path: '/pesticide',
        name: '农药残留',
        icon: pesticide,
        component: () => import('@/views/pesticide/index.vue')
      },{
        path: '/beast',
        name: '兽药残留',
        icon: beast,
        component: () => import('@/views/beast/index.vue')
      },{
        path: '/history',
        name: '历史记录',
        icon: history,
        component: () => import('@/views/history/index.vue')
      },{
        path: '/system',
        name: '系统设置',
        icon: system,
        component: () => import('@/views/system/index.vue')
      },
    ]
  },{
    path: "/login",
    component: () => import('@/views/login/index.vue'),
  }
];

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

const whiteList = ['/login']

router.beforeEach((to, from, next) => {
  if(getToken()){
    next()
  }else{
    // 在免登录白名单，直接进入
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    }else {
      next('/login') // 否则全部重定向到登录页
    }
  }
});

export default router
