import Cookies from 'js-cookie'

// 设置token
const tokenKey = 'web-token'
// 获取token的操作
export function getToken(){
    return Cookies.get(tokenKey)
}
// 删除token
export function removeToken(){
    return Cookies.remove(tokenKey)
}
// 设置token
export function setToken(token){
    return Cookies.set(tokenKey,token)
}

// 设备
const equipment = 'equipment'
export function getEquipment(){
    return Cookies.get(equipment)
}
export function removeEquipment(){
    return Cookies.remove(equipment)
}
export function setEquipment(data){
    return Cookies.set(equipment,data)
}

// 设备
const read = 'read'
export function getRead(){
    return Cookies.get(read)
}
export function removeRead(){
    return Cookies.remove(read)
}
export function setRead(data){
    return Cookies.set(read,data)
}
