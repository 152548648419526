<script>
import Cookies from "js-cookie";
export default {
  name: "index",
  data(){
    return{
      title: '系统管理员',
      squareUrl: "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
    }
  },
  methods:{
    handleCommand(command) {
      console.log("command",command)
      switch (command){
        case 'info':
          this.$router.push({path: '/system'})
          break;
        case 'logout':
          this.$confirm('此操作将检测系统, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if(Cookies.get("rememberMe")){
              Cookies.remove("token");
            }else{
              Cookies.remove("username");
              Cookies.remove("password");
              Cookies.remove("rememberMe");
              Cookies.remove("token");
            }
            this.$router.push({path: '/login'})
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消操作'
            });
          });

          break;
      }
    }
  }
}
</script>

<template>
  <div class="userRole-page">
<!--    <div>-->
<!--      当前账号-->
<!--      <span style="color: #e60069;">-->
<!--        {{title}}-->
<!--      </span>-->
<!--      你好！欢迎你-->
<!--    </div>-->
    <div>
      <el-dropdown  @command="handleCommand">
        <el-avatar shape="square" size="medium" :src="squareUrl"></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="info">个人中心</el-dropdown-item>
          <el-dropdown-item command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<style scoped lang="scss">
.userRole-page{
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 15px;
}
</style>
